import React, { useContext } from 'react';
import {
  Button, Card, Badge, Row,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PropTypes, { arrayOf } from 'prop-types';
import { ThemeContext } from 'styled-components';
import ReactMarkdown from 'react-markdown';
import Moment from 'react-moment';

import optimizeUrl from '../../utils/imageLinkHandler';

const styles = {
  cardTitleStyle: {
    fontSize: 24,
    fontWeight: 700,
  },
  cardTextStyle: {
    textAlign: 'left',
  },
  linkStyle: {
    textDecoration: 'none',
    padding: 10,
  },
  buttonStyle: {
    margin: 3,
  },
};

const ProjectCard = (props) => {
  const theme = useContext(ThemeContext);

  const { project } = props;

  return (
    <Card
      style={{
        backgroundColor: theme.cardBackground,
        borderColor: theme.cardBorderColor,
      }}
      text={theme.bsSecondaryVariant}
    >
      <Link
        to={`/project/${project.id}`}
      >
        <Card.Img
          variant="top"
          src={optimizeUrl(project.attributes.coverImage.data?.attributes.url)}
          className="img-fluid"
          alt={project.attributes.coverImage.data?.attributes.alternativeText}
        />
      </Link>
      <Card.Body>
        <Card.Title style={styles.cardTitleStyle} className="mb-3">
          <Link
            to={`/project/${project.id}`}
          >
            <strong>{project.attributes.Title}</strong>
          </Link>
        </Card.Title>

        <ReactMarkdown className="card-text text-start overflow-auto pe-1" children={project.attributes.context} />
      </Card.Body>
      <Card.Footer className="px-3 mt-2">
        <Row>
          <Link
            to={`/project/${project.id}`}
          >
            <Button
              key={project.id}
              style={styles.buttonStyle}
              variant="outline-warning"
              className="w-50 mx-auto"
            >
              View Project
            </Button>
          </Link>
        </Row>
        <div className="d-flex justify-content-center align-items-center my-2">
          {project.attributes.tags.data?.map((tag) => (
            <Badge
              key={tag.attributes.Name}
              pill
              bg={theme.bsSecondaryVariant}
              text={theme.bsPrimaryVariant}
              style={styles.badgeStyle}
              className="mx-1 px-2 py-1"
            >
              {tag.attributes.Name}
            </Badge>
          ))}
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <i className="bi bi-person" />
            <small>{project.attributes.teamNumber}</small>
          </div>
          <Moment format="MMM YYYY" date={project.attributes.durationDate.endDate} />
        </div>
      </Card.Footer>
    </Card>
  );
};

ProjectCard.propTypes = {
  project: PropTypes.shape({
    id: PropTypes.string.isRequired,
    attributes: PropTypes.shape({
      Title: PropTypes.string.isRequired,
      Build: PropTypes.shape({
        data: PropTypes.shape({
          attributes: PropTypes.shape({
            url: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
          }),
        }),
      }),
      context: PropTypes.string.isRequired,
      coverImage: PropTypes.shape({
        data: PropTypes.shape({
          attributes: PropTypes.shape({
            alternativeText: PropTypes.string.isRequired,
            formats: PropTypes.shape({
              small: PropTypes.shape({
                url: PropTypes.string.isRequired,
              }),
            }),
            url: PropTypes.string.isRequired,
          }),
        }),
      }),
      durationDate: PropTypes.shape({
        endDate: PropTypes.string,
      }),
      teamNumber: PropTypes.string.isRequired,
      tags: PropTypes.shape({
        data: arrayOf(PropTypes.shape({
          attributes: PropTypes.shape({
            Name: PropTypes.string.isRequired,
          }),
        })),
      }),
      links: PropTypes.shape({
        data: arrayOf(PropTypes.shape({
          attributes: PropTypes.shape({
            Url: PropTypes.string.isRequired,
          }),
        })),
      }),
    }).isRequired,
  }).isRequired,
};

export default ProjectCard;
