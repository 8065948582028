import gql from 'graphql-tag';

const EDUCATIONS_QUERY = gql`
    query Educations {
      educations(sort: "durationDate.startDate:desc") {
        data {
          attributes {
            schoolName
            degreeName
            icon {
              data {
                attributes {
                  url
                  alternativeText
                  name
                }
              }
            }
            description
            durationDate {
              startDate
              endDate
            }
            major
            location
          }
        }
      }
    }
`;

export default EDUCATIONS_QUERY;
