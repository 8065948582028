import React, { useContext } from 'react';
import { Timeline, TimelineItem } from 'vertical-timeline-component-for-react';
import ReactMarkdown from 'react-markdown';
import { ThemeContext } from 'styled-components';
import Fade from 'react-reveal';
import moment from 'moment';
import { Container } from 'react-bootstrap';

import Header from './Header';
import EXPERIENCES_QUERY from '../queries/category/experiences';
import Query from './Query';

import '../css/experience.css';

const styles = {
  ulStyle: {
    listStylePosition: 'outside',
    paddingLeft: 20,
  },
  subtitleContainerStyle: {
    marginTop: 10,
    marginBottom: 10,
  },
  subtitleStyle: {
    display: 'inline-block',
  },
  inlineChild: {
    display: 'inline-block',
  },
  itemStyle: {
    marginBottom: 0,
  },
};

function Experience() {
  const theme = useContext(ThemeContext);

  function getDateText(startedDate, endDate) {
    let dateText = moment(startedDate).format('MMM');
    const startedYear = moment(startedDate).format('YYYY');

    if (endDate) {
      const endedYear = moment(endDate).format('YYYY');

      if (startedYear !== endedYear) {
        dateText += ' ' + startedYear;
      }

      dateText += ' - ' + moment(endDate).format('MMM YYYY');
    } else {
      dateText += ' - Present';
    }

    return dateText;
  }

  return (
    <Container>
      <Header title="Experience" />
      <div className="section-content-container" id="experience">
        <Query query={EXPERIENCES_QUERY} id={null}>
          {({ data: { experiences } }) => (
            <Fade>
              <Timeline
                lineColor={theme.timelineLineColor}
              >
                {experiences.data.map((item) => (
                  <TimelineItem
                    key={item.attributes.jobName + ' - ' + item.attributes.enterprise}
                    dateText={getDateText(item.attributes.durationDate.startDate, item.attributes.durationDate.endDate)}
                    dateInnerStyle={{ background: theme.accentColor }}
                    style={styles.itemStyle}
                    bodyContainerStyle={{ color: theme.color }}
                  >
                    <h2 className="item-title">
                      {item.attributes.jobName}
                    </h2>
                    <div style={styles.subtitleContainerStyle}>
                      <h4 style={{ ...styles.subtitleStyle, color: theme.accentColor }}>
                        {item.attributes.enterprise}
                      </h4>
                      {item.attributes.jobType && (
                        <h5 style={styles.inlineChild}>
                              &nbsp;·
                          {' '}
                          {item.attributes.jobType}
                        </h5>
                      )}
                    </div>
                    <ReactMarkdown children={item.attributes.description} />
                  </TimelineItem>
                ))}
              </Timeline>
            </Fade>
          )}
        </Query>
      </div>
    </Container>
  );
}

export default Experience;
