import React, { useContext, useEffect } from 'react';
import {
  Button, Carousel, Container,
} from 'react-bootstrap';
import moment from 'moment';
import Moment from 'react-moment';
import ReactMarkdown from 'react-markdown';
import Iframe from 'react-iframe';
import { useParams } from 'react-router';
import { SocialIcon } from 'react-social-icons';
import { ThemeContext } from 'styled-components';
import Header from './Header';

import Query from './Query';
import PROJECT_QUERY from '../queries/project';
import optimizeUrl from '../utils/imageLinkHandler';

import '../css/projectPage.css';

const styles = {
  containerStyle: {
    marginBottom: 25,
  },
  showMoreStyle: {
    margin: 25,
  },

  carouselControlsStyle: {
    fontSize: '2rem',
    color: 'var(--main-highlight-color)',
    textShadow: '-1px 0 var(--main-bg-color), 0 1px var(--main-bg-color), 1px 0 var(--main-bg-color), 0 -1px var(--main-bg-color)',
  },
};

const ProjectPage = () => {
  const theme = useContext(ThemeContext);

  const { slug } = useParams();
  const nextIcon = <i className="bi bi-arrow-right-circle" style={styles.carouselControlsStyle} />;
  const prevIcon = <i className="bi bi-arrow-left-circle" style={styles.carouselControlsStyle} />;

  let toolTypeUsed = '';

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  function formatStartDate(startDate) {
    return moment(startDate).format('MMM YYYY').toString();
  }

  function getTagByType(tags, type) {
    const tag = tags.find((t) => t.attributes.type === type);
    return tag ? tag.attributes.Name : '';
  }

  function selectToolTypeUsed(tags) {
    const engineTag = tags.find((tag) => tag.attributes.type === 'engine');
    toolTypeUsed = engineTag ? 'engine' : 'framework';
    return toolTypeUsed;
  }

  return (
    <Query query={PROJECT_QUERY} slug={slug}>
      {({ data: { project } }) => (
        project.data ? (
          <div id="project-page">
            <div className="project-banner" style={{ backgroundImage: `url(${(optimizeUrl(project.data.attributes.coverImage.data?.attributes.url))})` }}>
              <Header title={project.data.attributes.Title} />
            </div>
            <div className="section-content-container mx-auto p-3">
              <Container style={styles.containerStyle}>
                <div className="py-3 mx-auto" id="project-info">
                  <table className="mx-auto fs-5">
                    <tbody>
                      <tr>
                        <th scope="row">Status</th>
                        <td>{project.data.attributes.status}</td>
                      </tr>
                      <tr>
                        <th scope="row">Date</th>
                        <td>{formatStartDate(project.data.attributes.durationDate.startDate)}</td>
                      </tr>
                      <tr>
                        <th scope="row">Duration</th>
                        <td>
                          {project.data.attributes.durationDate.endDate
                            ? (
                              <Moment
                                duration={project.data.attributes.durationDate.startDate}
                                date={project.data.attributes.durationDate.endDate}
                                format="D"
                                element="span"
                              />
                            )
                            : (
                              <Moment
                                duration={project.data.attributes.durationDate.startDate}
                                durationFromNow
                                format="D"
                                element="span"
                              />
                            )}
                          {' '}
                          days
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Type</th>
                        <td>{project.data.attributes.type}</td>
                      </tr>
                      <tr>
                        <th
                          scope="row"
                          style={{ textTransform: 'capitalize' }}
                        >
                          {selectToolTypeUsed(project.data.attributes.tags.data)}
                        </th>
                        <td>{getTagByType(project.data.attributes.tags.data, toolTypeUsed)}</td>
                      </tr>
                      <tr>
                        <th scope="row">Language</th>
                        <td>{getTagByType(project.data.attributes.tags.data, 'language')}</td>
                      </tr>
                      <tr>
                        <th scope="row">Team size</th>
                        <td>
                          Group of
                          {' '}
                          {project.data.attributes.teamNumber}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="mt-4">
                    {project.data.attributes.links.data.map((link) => (
                      link.attributes.Url ? (
                        <SocialIcon
                          key={link.attributes.link_type.data.attributes.Name}
                          style={styles.iconStyle}
                          url={link.attributes?.Url}
                          network={link.attributes.link_type.data.attributes.Name.toLowerCase()}
                          bgColor={theme.socialIconBgColor}
                          target="_blank"
                          className="mx-2"
                        />
                      )
                        : null
                    ))}
                  </div>
                </div>
                <hr />
                <div className="project-description">
                  <div className="section">
                    <h3 className="section-title highlight">Context</h3>
                    <ReactMarkdown className="section-body" children={project.data.attributes.context} />
                  </div>
                  <div className="section fs-5">
                    <h3 className="section-title highlight">Description</h3>
                    <ReactMarkdown className="section-body" children={project.data.attributes.description} />
                  </div>
                  <hr />
                  <div className="section fs-5">
                    <h3 className="section-title highlight">Screenshots</h3>
                    {(project.data.attributes.Images.data.length === null || project.data.attributes.Images.data.length === 0) && project.data.attributes.coverImage.data === null
                      ? <p className="section-body">No screenshots available</p>
                      : (
                        <Carousel variant="dark" interval="3500" nextIcon={nextIcon} prevIcon={prevIcon}>
                          {project.data.attributes.coverImage.data && (
                            <Carousel.Item key={project.data.attributes.coverImage?.id}>
                              <img
                                className="d-block w-100 img-fluid"
                                      /* eslint-disable-next-line max-len */
                                src={optimizeUrl(project.data.attributes.coverImage.data.attributes.url)}
                                alt={project.data.attributes.coverImage.data.attributes.alternativeText}
                              />
                              {project.data.attributes.coverImage.data.attributes.caption && (
                              <Carousel.Caption>
                                <h4 className="text-light p-2 mx-auto w-75 fs-5 shadow text-decoration-underline">{project.data.attributes.coverImage.data.attributes.caption}</h4>
                              </Carousel.Caption>
                              )}
                            </Carousel.Item>
                          )}
                          {project.data.attributes.Images.data?.map((image) => (
                            <Carousel.Item key={image.id}>
                              <img
                                className="d-block w-100 img-fluid"
                                      /* eslint-disable-next-line max-len */
                                src={optimizeUrl(image.attributes.url)}
                                alt={image.attributes.alternativeText}
                              />
                              {image.attributes.caption && (
                                <Carousel.Caption>
                                  <h4 className="text-light p-2 mx-auto w-75 fs-5 shadow text-decoration-underline">{image.attributes.caption}</h4>
                                </Carousel.Caption>
                              )}
                            </Carousel.Item>
                          ))}
                        </Carousel>
                      )}
                  </div>
                  <div className="section">
                    <h3 className="section-title highlight">Gameplay</h3>
                    {project.data.attributes.gameplayUrl
                      ? <Iframe title="Project gameplay" url={project.data.attributes.gameplayUrl} className="mx-auto w-100" />
                      : <p className="section-body">No gameplay available</p>}
                  </div>
                  <div className="section">
                    <h3 className="section-title highlight">Play in browser</h3>
                    {project.data.attributes.playLink
                      // eslint-disable-next-line max-len
                      ? (
                        <a
                          href={project.data.attributes.playLink}
                          target="_blank"
                          rel="noreferrer"
                          className="w-100 d-block"
                        >
                          <Button variant="dark" className="w-100 fs-2" id="download-btn">
                            Play in browser
                          </Button>
                        </a>
                      )
                      : <p className="section-body">Unavailable for this game</p>}
                  </div>
                  <div className="section">
                    <h3 className="section-title highlight">Download the game</h3>
                    {project.data.attributes.build
                      // eslint-disable-next-line max-len
                      ? (
                        <a href={project.data.attributes.build} target="_blank" rel="noreferrer" className="w-100 d-block">
                          <Button variant="dark" className="w-100 fs-2" id="download-btn">
                            Download
                          </Button>
                        </a>
                      )
                      : <p className="section-body">No download available</p>}
                  </div>
                </div>
                <hr />

              </Container>
            </div>
          </div>
        ) : (
          <Container>
            <Header title="Project not found" />
          </Container>
        )
      )}
    </Query>
  );
};

export default ProjectPage;
