import gql from 'graphql-tag';

const EXPERIENCES_QUERY = gql`
  query Experiences {
    experiences(sort: "durationDate.startDate:desc") {
      data {
        attributes {
          jobName
          enterprise
          jobType
          description
          durationDate {
            startDate
            endDate
          }
        }
      }
    }
  }
`;

export default EXPERIENCES_QUERY;
