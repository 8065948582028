import React from 'react';
import { useQuery } from 'react-apollo';
import FallbackSpinner from '../FallbackSpinner';

// eslint-disable-next-line react/prop-types
const Query = ({ children, query, slug }) => {
  const { data, loading, error } = useQuery(query, {
    variables: { slug },
  });

  if (loading) return <FallbackSpinner />;
  if (error) {
    return (
      <div>
        {process.env.NODE_ENV === 'development' ? (
          <div>
            <p>
              Error in:
              {query}
            </p>
            <p>
              {JSON.stringify(error)}
            </p>
          </div>
        ) : (
          <p>
            Error fetching data. Please refresh the page.
          </p>
        )}
      </div>
    );
  }
  if (process.env.NODE_ENV === 'development') console.log(data);
  return children({ data });
};

export default Query;
