import React from 'react';

const AvailableStatus = () => (
  <div className="font-weight-semi-bold text-decoration-none">
    <span className="d-block">Available for:</span>
    <div className="d-flex justify-content-center align-items-center">
      <div className="custom-available-bullet text-center" />
      <span className="opacity-6 text-uppercase text-2">INTERNSHIP</span>
    </div>
  </div>
);

export default AvailableStatus;
