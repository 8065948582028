import React, { useContext } from 'react';
import { Chrono } from 'react-chrono';
import { Container } from 'react-bootstrap';
import Fade from 'react-reveal';
import { ThemeContext } from 'styled-components';
import ReactMarkdown from 'react-markdown';
import moment from 'moment';
import Header from './Header';

import Query from './Query';
import EDUCATIONS_QUERY from '../queries/category/educations';

import '../css/education.css';

function Education() {
  const theme = useContext(ThemeContext);

  function setDate(startedDate, endDate) {
    const startYear = moment(startedDate).format('YYYY');
    const endYear = moment(endDate).format('YYYY');
    let dateText = startYear + ' - ' + endYear;

    if (endYear > moment().format('YYYY')) {
      dateText += ' (Expected)';
    }

    return dateText;
  }

  function getItems(data) {
    const items = [];

    data.forEach((item) => {
      items.push({
        title: setDate(item.attributes.durationDate.startDate, item.attributes.durationDate.endDate),
        cardTitle: item.attributes.schoolName + ', ' + item.attributes.location,
        cardSubtitle: item.attributes.degreeName,
      });
    });

    return items;
  }

  return (
    <Container>
      <Header title="Education" />
      <div className="section-content-container pb-3 pt-4" id="education">
        <Query query={EDUCATIONS_QUERY} id={null}>
          {({ data: { educations } }) => (
            <Fade>
              <div className="w-100">
                <Chrono
                  hideControls
                  allowDynamicUpdate
                  slideShow
                  useReadMore={false}
                  items={getItems(educations.data)}
                  mode="VERTICAL_ALTERNATING"
                  cardWidth={600}
                  theme={{
                    primary: theme.accentColor,
                    secondary: theme.accentColor,
                    cardBgColor: theme.chronoTheme.cardBgColor,
                    cardForeColor: theme.chronoTheme.cardForeColor,
                    titleColor: theme.chronoTheme.titleColor,
                  }}
                >
                  <div className="chrono-icons">
                    {educations.data.map((education) => (
                      <img
                        key={education.attributes.icon.data?.attributes.name}
                        src={education.attributes.icon.data?.attributes.url}
                        alt={education.attributes.icon.data?.attributes.alternativeText}
                        className="rounded-circle img-fluid"
                      />
                    ))}
                  </div>
                  {educations.data.map((education) => (
                    <div className="w-100" key={education.attributes.schoolName}>
                      {education.attributes.major > 0 ? (
                        <div
                          className="m-0 fs-6 d-flex text-end justify-content-end align-items-center"
                          id="major"
                        >
                          <span>
                            {education.attributes.major}
                            x first of the class
                          </span>
                          <img
                            src="images/icons/crown.png"
                            alt="crown icon"
                            className="ms-1"
                            width="16"
                            height="16"
                          />
                        </div>
                      ) : null}
                      <ReactMarkdown className="text-start pt-1" children={education.attributes.description} />
                    </div>
                  ))}
                </Chrono>
              </div>
            </Fade>
          )}
        </Query>
      </div>
    </Container>
  );
}

export default Education;
