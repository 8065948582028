import React, { useContext } from 'react';
import { SocialIcon } from 'react-social-icons';
import { ThemeContext } from 'styled-components';

import Query from './Query';
import SOCIAL_QUERY from '../queries/category/socials';

const styles = {
  iconStyle: {
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 10,
  },
};

function Social() {
  const theme = useContext(ThemeContext);

  return (
    <div className="social">
      <Query query={SOCIAL_QUERY}>
        {({ data: { socials } }) => (
          socials.data?.map((social) => (
            <SocialIcon
              key={'footer ' + social.attributes.link_type.data.attributes.Name}
              style={styles.iconStyle}
              url={social.attributes.link}
              network={social.attributes.link_type.data.attributes.Name.toLowerCase()}
              bgColor={theme.socialIconBgColor}
              target="_blank"
              rel="noopener"
            />
          ))
        )}
      </Query>
    </div>
  );
}

export default Social;
