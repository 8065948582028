// eslint-disable-next-line import/no-extraneous-dependencies
import { ApolloClient } from 'apollo-client';
// eslint-disable-next-line import/no-extraneous-dependencies
import { InMemoryCache } from 'apollo-cache-inmemory';
// eslint-disable-next-line import/no-extraneous-dependencies
import { HttpLink } from 'apollo-link-http';

const cache = new InMemoryCache();
const link = new HttpLink({
  uri: `${process.env.REACT_APP_DATABASE_URL}/graphql`,
});
const client = new ApolloClient({
  cache,
  link,
});

export default client;
