import React from 'react';
import Fade from 'react-reveal';
import { Col, Container, Row } from 'react-bootstrap';

import Header from './Header';
import Query from './Query';
import SKILLS_QUERY from '../queries/category/skills';

const styles = {
  iconStyle: {
    maxHeight: 75,
    maxWidth: 75,
    aspectRatio: '1/1',
    objectFit: 'contain',
    margin: 5,
  },
  introTextContainer: {
    whiteSpace: 'pre-wrap',
  },
};

function Skills() {
  return (
    <Container>
      <Header title="Skills" className="text-center" />
      <div className="section-content-container mt-4" id="skills">

        <div style={styles.introTextContainer}>
          <p className="mb-0">
            I love to learn new things and experiment with new technologies.
          </p>
          <p>
            Here are some of the major languages, game engines and tools I have worked with :
          </p>
        </div>
        <Fade>
          <div className="mt-5">
            <Query query={SKILLS_QUERY} id={null}>
              {({ data: { skillTypes } }) => (
                skillTypes.data?.map((skillType) => (
                  <Fade>
                    <Row key={skillType.attributes.title} className="align-items-center my-3 px-2">
                      <Col className="text-start my-2" xs={12} lg={4}>
                        <h3 className="underline">
                          {skillType.attributes.title}
                        </h3>
                      </Col>
                      <Col className="text-start text-md-center">
                        {skillType.attributes.skills.data?.map((skill) => (
                          <div
                            key={skill.attributes.title}
                            style={{ display: 'inline-block' }}
                            className="px-1 text-center"
                          >
                            {skill.attributes.icon ? (
                              <img
                                style={styles.iconStyle}
                                src={skill.attributes.icon.data?.attributes.url}
                                alt={skill.attributes.icon.data?.attributes.alternativeText}
                                className="img-fluid"
                              />
                            ) : null}
                            <p className="font-weight-extra-light text-center mx-auto fs-6">{skill.attributes.title}</p>
                          </div>
                        ))}
                      </Col>
                    </Row>
                  </Fade>
                ))
              )}
            </Query>
          </div>
        </Fade>
      </div>
    </Container>
  );
}

export default Skills;
