import gql from 'graphql-tag';

const SKILLS_QUERY = gql`
    query Skills {
      skillTypes {
        data {
          attributes {
            title
            skills(sort: "title") {
              data {
                attributes {
                  title
                  icon {
                    data {
                      attributes {
                        url
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
`;

export default SKILLS_QUERY;
