import gql from 'graphql-tag';

const PROJECTS_QUERY = gql`
  query Projects {
    projects(sort: "durationDate.startDate:desc") {
      data {
        id
        attributes {
          context
          Title
          coverImage {
            data {
              attributes {
                formats
                alternativeText
                url
              }
            }
          }
          durationDate {
            endDate
          }
          teamNumber
          tags {
            data {
              attributes {
                Name
                type
              }
            }
          }
          links {
            data {
              attributes {
                Url
                link_type {
                  data {
                    attributes {
                      Name
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default PROJECTS_QUERY;
