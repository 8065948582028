import React from 'react';
import Typewriter from 'typewriter-effect';
import Fade from 'react-reveal';
import { Container } from 'react-bootstrap';
import Social from './Social';

import '../css/home.css';

const styles = {
  nameStyle: {
    fontSize: 'max(3em, 5vw)',
  },
  inlineChild: {
    display: 'inline-block',
  },
};

function Home() {
  return (
    <div className="section-content-container px-4 d-flex" id="home">
      <Container>
        <Fade>
          <h1 style={styles.nameStyle}>
            Hugo MAESTRACCI
          </h1>
          <div style={{ flexDirection: 'row' }}>
            <h2 style={styles.inlineChild}>I&apos;m&nbsp;a&nbsp;</h2>
            <Typewriter
              options={{
                loop: true,
                autoStart: true,
                strings: ['Programmer', 'Game Programmer', 'Gamer'],
              }}
            />
          </div>
          <Social />
        </Fade>
      </Container>
    </div>

  );
}

export default Home;
