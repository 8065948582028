import gql from 'graphql-tag';

const PROJECT_QUERY = gql`
query Project($slug: ID) {
  project(id: $slug) {
    data {
      attributes {
        Title
        Images {
          data {
            attributes {
              caption
              alternativeText
              formats
              url
            }
          }
        }
        status
        gameplayUrl
        teamNumber
        context
        description
        type
        build
        coverImage {
          data {
            attributes {
              caption
              alternativeText
              formats
              url
            }
          }
        }
        links {
          data {
            attributes {
              link_type {
                data {
                  attributes {
                    Name
                  }
                }
              }
              Url
            }
          }
        }
        playLink
        tags {
          data {
            attributes {
              Name
              type
            }
          }
        }
        durationDate {
          startDate
          endDate
        }
      }
    }
  }
}
`;

export default PROJECT_QUERY;
