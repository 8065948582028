import React from 'react';
import Fade from 'react-reveal';
import { Col, Container, Row } from 'react-bootstrap';

import Header from './Header';
import '../css/about.css';

function About() {
  return (
    <Container id="About" className="py-5">
      <div className="section-content-container d-flex" id="about-main">
        <Row>
          <Col xs={12} md={6} className="p-4 d-flex text-start justify-content-center flex-column">
            <Header title="About" />
            <Fade>
              <p className="fs-5 text-justify mb-4">
                Hello, I am
                {' '}
                <strong>Hugo MAESTRACCI</strong>
                , a Programmer specialized in
                {' '}
                <strong>video games</strong>
                {' '}
                for over 4 years
              </p>
              <p className="fs-5 text-justify">
                {/* eslint-disable-next-line max-len */}
                Passionate about video games since my early years, I&apos;ve always found joy in assisting my peers with
                computer-related issues.
              </p>
              <p className="fs-5 text-justify mt-1">
                It was through my father&apos;s support and trust that I delved into the realm
                of computer science without any restrictions
              </p>
            </Fade>
          </Col>
          <Col className="col-lg-5 pt-5 pt-lg-0 text-lg-end">
            <Fade>
              <div className="relative">
                <img
                  src="images/about/profile.jpg"
                  alt="profile"
                  className="img-fluid border"
                  id="profile"
                />
                <span className="dot top-right" />
                <span className="dot bottom-left" />
              </div>
            </Fade>
          </Col>
        </Row>
      </div>
      <div className="mb-5">
        <Row className="about-section my-5">
          <Col className="about-title-section" xs={12} md={6}>
            <h3 className="fs-1">
              SCHOOL
            </h3>
          </Col>
          <Col>
            <p className="fs-5 p-4 text-justify">
              {/* eslint-disable-next-line max-len */}
              After completing my high school education, I knew my career path was in the field of
              {' '}
              <strong>computer science</strong>
              ,
              leading me to pursue a bachelor&apos;s degree in the same. During the onset of the Covid pandemic, I
              ventured into
              {' '}
              <strong>self-taught game development</strong>
              , realizing over time the importance of a solid foundation and
              formal education in turning my passion into a future profession, which led me to enroll at the Gaming
              Campus.
            </p>
          </Col>
        </Row>
        <Row className="about-section my-5 flex-row-reverse">
          <Col className="about-title-section" xs={12} md={6}>
            <h3 className="fs-1">
              PASSION
            </h3>
          </Col>
          <Col className="fs-5 p-4 text-justify">
            <p>
              {/* eslint-disable-next-line max-len */}
              I thrive in team collaborations, relishing the opportunity to share my skills while benefiting from the
              {' '}
              <strong>exchange of knowledge</strong>
              . My love for the gaming industry goes beyond programming.
            </p>
            <p className="mt-1">
              I also have an affinity for animation (3D and 2D puppet) and possess skills in 2D-3D art.
            </p>
            <p className="mt-1">
              As an avid gamer and enthusiast of the
              esports scene 🐝, I&apos;m deeply immersed in all facets of this dynamic world.
            </p>
          </Col>
        </Row>
        <Row className="about-section my-5">
          <Col className="about-title-section" xs={12} md={6}>
            <h3 className="fs-1">
              FUTURE
            </h3>
          </Col>
          <Col className="fs-5 p-4 text-justify">
            <p>
              My ultimate goal would be to set up my own video game studio so I can finally make the games I&apos;ve
              been dreaming about for so long.
            </p>
            <p className="my-1 fw-bold">
              I&apos;m determined to stack the odds in my favor to achieve this ambitious goal.
            </p>
          </Col>
        </Row>
      </div>
    </Container>

  );
}

export default About;
