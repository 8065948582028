import gql from 'graphql-tag';

const NAVBAR_QUERY = gql`
query Resume {
  resume {
    data {
      attributes {
        resumeFile {
          data {
            attributes {
              url
            }
          }
        }
      }
    }
  }
}
`;

export default NAVBAR_QUERY;
