import React, { Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';

import NavBarWithRouter from './components/NavBar';
import FallbackSpinner from './components/FallbackSpinner';
import Home from './components/Home';
import ProjectPage from './components/ProjectPage';
import Skills from './components/Skills';
import Education from './components/Education';
import Experience from './components/Experience';
import Projects from './components/Projects';
import About from './components/About';
import Footer from './components/Footer';
/*
import NotFound from './components/NotFound';
*/

function MainApp() {
  return (
    <>
      <NavBarWithRouter />
      <main className="main">
        <Switch>
          <Suspense fallback={<FallbackSpinner />}>
            <Route exact path="/" component={Home} />
            <Route exact path="/about" component={About} />
            <Route exact path="/skills" component={Skills} />
            <Route exact path="/education" component={Education} />
            <Route exact path="/experience" component={Experience} />
            <Route exact path="/projects" component={Projects} />
            <Route exact path="/project/:slug" component={ProjectPage} />
            {/*
            <Route path="*" component={NotFound} />
*/}
          </Suspense>
        </Switch>
        <Footer />
      </main>
    </>
  );
}

export default MainApp;
