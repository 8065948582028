import gql from 'graphql-tag';

const SOCIALS_QUERY = gql`
query Socials {
  socials {
    data {
      attributes {
        link
        link_type {
          data {
            attributes {
              Name
            }
          }
        }
      }
    }
  }
}
`;

export default SOCIALS_QUERY;
