import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import Header from './Header';
import ProjectCard from './projects/ProjectCard';

import PROJECTS_QUERY from '../queries/category/projects';
import Query from './Query';

import '../css/projects.css';

const Projects = () => (
  <Container>
    <Header title="Projects" />
    <Fade>
      <div className="section-content-container mb-3 mt-4 pt-4 px-1" id="projects">
        <Query query={PROJECTS_QUERY} id={null}>
          {({ data: { projects } }) => (
            <Row xs={1} sm={1} md={2} lg={3} className="g-4">
              {projects.data.map((project) => (
                <Fade key={project.attributes.Title}>
                  <Col className="px-2 px-sm-0">
                    <ProjectCard project={project} id={project.id} />
                  </Col>
                </Fade>
              ))}
            </Row>
          )}
        </Query>
      </div>
    </Fade>
  </Container>
);

export default Projects;
