function optimizeUrl(imageUrl) {
  if (imageUrl === null || imageUrl === undefined) {
    return '';
  }

  const searchValue = 'upload/';

  const uploadIndex = imageUrl.search(searchValue) + searchValue.length;
  const optimizedUrl = imageUrl.slice(0, uploadIndex) + 'f_auto,q_auto/' + imageUrl.slice(uploadIndex);

  console.log(optimizedUrl);

  return optimizedUrl;
}

export default optimizeUrl;
