import React from 'react';
import { Container } from 'react-bootstrap';

import Social from './Social';
import AvailableStatus from './AvailableStatus';

import '../css/footer.css';

const Footer = () => (
  <footer>
    <Container className="py-3 my-4 border-top">
      <div>
        <div
          className="appear-animation d-block d-sm-none"
          data-appear-animation="maskUp"
          data-appear-animation-delay="300"
        >
          <AvailableStatus />
        </div>
        <Social />
      </div>
    </Container>
  </footer>
);

export default Footer;
