import {
  Navbar, Nav, Container,
} from 'react-bootstrap';
import React, { useState, useContext } from 'react';
import { withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';
import styled, { ThemeContext } from 'styled-components';

/*
import AvailableStatus from './AvailableStatus';
*/
import Query from './Query';
import NAVBAR_QUERY from '../queries/navbar';

import '../css/theme.css';
import '../css/navbar.css';

const styles = {
  logoStyle: {
    width: 50,
    height: 50,
  },
};

/* const ExternalNavLink = styled.a`
  color: ${(props) => props.theme.navbarTheme.linkColor};
  &:hover {
    color: ${(props) => props.theme.navbarTheme.linkHoverColor};
  }
  &::after {
    background-color: ${(props) => props.theme.accentColor};
  }
`; */

const InternalNavLink = styled(NavLink)`
  color: ${(props) => props.theme.navbarTheme.linkColor};
  &:hover {
    color: ${(props) => props.theme.navbarTheme.linkHoverColor};
  }
  &::after {
    background-color: ${(props) => props.theme.accentColor};
  }
  &.navbar__link--active {
    color: ${(props) => props.theme.navbarTheme.linkActiveColor};
  }
`;

const NavBar = () => {
  const theme = useContext(ThemeContext);
  /*
  const [data, setData] = useState(null);
*/
  const [expanded, setExpanded] = useState(false);

  return (
    <Navbar
      sticky="top"
      expand="lg"
      className="navbar-custom py-3 d-flex align-items-center justify-content-center"
      expanded={expanded}
      id="navbar"
    >
      <Container className="justify-content-between" id="navbar-container">
        <Navbar.Brand href="/" className="me-0">
          <img
            src="/images/about/profile_lowres.jpg"
            className="d-inline-block align-top rounded-circle border border-2"
            alt="main logo"
            style={styles.logoStyle}
          />
        </Navbar.Brand>
        <div className="ps-2">
          <span className="d-block">Hugo MAESTRACCI</span>
        </div>
        <div className="col-md-2 appear-animation d-none d-sm-block" data-appear-animation="maskUp" data-appear-animation-delay="300">
          {/* <AvailableStatus /> */}
        </div>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={() => setExpanded(!expanded)}
        />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav>
            <InternalNavLink
              key="Home"
              onClick={() => setExpanded(false)}
              exact
              activeClassName="navbar__link--active"
              className="navbar__link"
              to="/"
              theme={theme}
            >
              Home
            </InternalNavLink>
            <InternalNavLink
              key="About"
              onClick={() => setExpanded(false)}
              activeClassName="navbar__link--active"
              className="navbar__link"
              to="/about"
              theme={theme}
            >
              About
            </InternalNavLink>
            <InternalNavLink
              key="Skills"
              onClick={() => setExpanded(false)}
              activeClassName="navbar__link--active"
              className="navbar__link"
              to="/skills"
              theme={theme}
            >
              Skills
            </InternalNavLink>
            <InternalNavLink
              key="Education"
              onClick={() => setExpanded(false)}
              activeClassName="navbar__link--active"
              className="navbar__link"
              to="/education"
              theme={theme}
            >
              Education
            </InternalNavLink>
            <InternalNavLink
              key="Experience"
              onClick={() => setExpanded(false)}
              activeClassName="navbar__link--active"
              className="navbar__link"
              to="/experience"
              theme={theme}
            >
              Experience
            </InternalNavLink>
            <InternalNavLink
              key="Projects"
              onClick={() => setExpanded(false)}
              activeClassName="navbar__link--active"
              className="navbar__link"
              to="/projects"
              theme={theme}
            >
              Projects
            </InternalNavLink>
            <Query query={NAVBAR_QUERY}>
              {({ data: { resume } }) => (
                <a
                  key="Resume"
                  href={resume.data?.attributes.resumeFile.data.attributes.url}
                  target="_blank"
                  onClick={() => setExpanded(false)}
                  className="navbar__link"
                  rel="noreferrer"
                >
                  Resume
                  <i className="bi bi-download" />
                </a>
              )}
            </Query>
          </Nav>
        </Navbar.Collapse>
      </Container>
      {process.env.NODE_ENV === 'development' ? (
        <div id="env-mode">
          <small className="user-select-none">
            You are running in
            {' '}
            <b className="text-uppercase">{process.env.NODE_ENV}</b>
            {' '}
            mode.
          </small>
        </div>
      ) : null}
    </Navbar>
  );
};

const NavBarWithRouter = withRouter(NavBar);
export default NavBarWithRouter;
